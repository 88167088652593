import VueRouter from "vue-router";

import LoginPage from "@/views/auth/LoginPage.vue";
import AuthIndex from "@/views/auth/AuthIndex.vue";
import RegisterPage from "@/views/auth/RegisterPage.vue";
import ChatIndex from "@/views/chat/ChatIndex.vue";
import AdminLayout from "@/layout/admin/AdminLayout.vue";
import AdminIndex from "@/views/admin/AdminIndex.vue";
import KeyList from "@/views/admin/KeyList.vue";
import UserList from "@/views/admin/UserList.vue";

const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(to) {
    return VueRouterPush.call(this, to).catch(err => err)
}

const router = new VueRouter({
    mode: 'history',
    routes:[
        {
            path: '/',
            component: AuthIndex,
            children: [
                {
                    path: '',
                    name: 'Login',
                    component: LoginPage
                },
                {
                    path: 'register',
                    name: 'Register',
                    component: RegisterPage
                }
            ]
        },
        {
            path: '/chat',
            name: 'Chat',
            component: ChatIndex
        },
        {
            path: '/admin',
            component: AdminLayout,
            children: [
                {
                    path: '',
                    name: 'AdminIndex',
                    component: AdminIndex
                },{
                    path: 'key',
                    name: 'KeyList',
                    component: KeyList
                },{
                    path: 'user',
                    name: 'UserList',
                    component: UserList
                }
            ]
        }
    ]
})

router.beforeEach((to, from, next) => {
    if (localStorage.getItem('authorization') == null && to.fullPath.startsWith('/chat')
        || localStorage.getItem('authorization') == null && to.fullPath.startsWith('/admin')) {
        next('/')
    } else {
        next()
    }
})

export default router;
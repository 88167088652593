import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router';
import router from '@/router';
import axios from 'axios';
import store from "@/store";
import Notification from '@/components/notification';
import ViewUI from 'view-design';
import 'view-design/dist/styles/iview.css';

Vue.use(VueRouter)
Vue.use(Notification)
Vue.use(ViewUI)

Vue.config.productionTip = false

axios.defaults.baseURL = 'https://chatapi.122345.xyz'
// 在请求发送前添加授权标头
axios.interceptors.request.use(
    config => {
        const token = localStorage.getItem('authorization');
        if (token && config.url.indexOf('/api/auth/') === -1 || config.url.indexOf('/api/auth/logout') > -1) {
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

new Vue({
    render: h => h(App),
    router: router,
    store,
    beforeCreate() {
        Vue.prototype.$bus = this
    }
}).$mount('#app')

<template>
  <Menu :theme="theme3" :active-name="activeIndex" width="auto" style="height: 100%" @on-select="onSelect">
    <MenuGroup title="统计分析">
      <MenuItem name="AdminIndex" to="/admin">
        <Icon type="md-heart" />
        管理首页
      </MenuItem>
    </MenuGroup>
    <MenuGroup title="内容管理">
      <MenuItem name="KeyList" to="/admin/key">
        <Icon type="md-document" />
        卡密管理
      </MenuItem>
      <MenuItem name="UserList" to="/admin/user">
        <Icon type="md-leaf" />
        用户管理
      </MenuItem>
    </MenuGroup>
  </Menu>
</template>
<script>
export default {
  name: 'AdminLeftMenu',
  data () {
    return {
      theme3: 'light',
      activeIndex: this.$route.name
    }
  },
  methods: {
    onSelect(){
      this.$emit('closeMenu')
    }
  },
  watch: {
    $route(to, from) {
      this.activeIndex = to.name
    }
  }
}
</script>
<style>

</style>
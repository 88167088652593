<template>
  <div class="login">
    <div class="subject">
      <h1 class="title">注 册</h1>
      <p class="details">欢迎注册KIL_AI</p>
    </div>
    <form>
      <div class="text-group">
        <i class="input-icon left-module">
          <svg t="1688445363370" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2073" width="20" height="20"><path d="M512 64c106.032 0 192 85.968 192 192s-85.968 192-192 192-192-85.968-192-192S405.968 64 512 64z m0 64a128 128 0 1 0 0 256 128 128 0 0 0 0-256zM512 480c208.176 0 378.688 152.096 383.872 343.024L896 832h-64c0-158.336-142.624-288-320-288-174.32 0-315.072 125.232-319.872 279.84L192 832H128c0-195.12 172.56-352 384-352zM192 832a32 32 0 0 0 28.272 31.792L224 864h576a32 32 0 0 0 31.792-28.272L832 832h64a96 96 0 0 1-90.352 95.84L800 928H224a96 96 0 0 1-95.84-90.352L128 832h64z" fill="#b1b3b5" p-id="2074"></path></svg>
        </i>
        <input v-model="username" type="text" name="username" placeholder="用户名">
      </div>
      <div class="text-group">
        <i class="input-icon left-module">
          <svg t="1688444703568" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1598" id="mx_n_1688444703569" width="20" height="20"><path d="M832 320a64 64 0 0 1 64 64v448a64 64 0 0 1-64 64H192a64 64 0 0 1-64-64V384a64 64 0 0 1 64-64h640z m0 64H192v448h640V384z" fill="#b1b3b5" p-id="1599"></path><path d="M480 512m32 0l0 0q32 0 32 32l0 128q0 32-32 32l0 0q-32 0-32-32l0-128q0-32 32-32Z" fill="#b1b3b5" p-id="1600"></path><path d="M512 64c106.032 0 192 85.968 192 192v128H320v-128c0-106.032 85.968-192 192-192z m0 64a128 128 0 0 0-127.84 121.6L384 256v64h256v-64a128 128 0 0 0-121.6-127.84L512 128z" fill="#b1b3b5" p-id="1601"></path></svg>
        </i>
        <input v-model="password" type="password" name="password" placeholder="密码">
      </div>
      <div class="text-group">
        <i class="input-icon left-module">
          <svg t="1688444703568" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1598" id="mx_n_1688444703569" width="20" height="20"><path d="M832 320a64 64 0 0 1 64 64v448a64 64 0 0 1-64 64H192a64 64 0 0 1-64-64V384a64 64 0 0 1 64-64h640z m0 64H192v448h640V384z" fill="#b1b3b5" p-id="1599"></path><path d="M480 512m32 0l0 0q32 0 32 32l0 128q0 32-32 32l0 0q-32 0-32-32l0-128q0-32 32-32Z" fill="#b1b3b5" p-id="1600"></path><path d="M512 64c106.032 0 192 85.968 192 192v128H320v-128c0-106.032 85.968-192 192-192z m0 64a128 128 0 0 0-127.84 121.6L384 256v64h256v-64a128 128 0 0 0-121.6-127.84L512 128z" fill="#b1b3b5" p-id="1601"></path></svg>
        </i>
        <input v-model="password_repeat" type="password" name="password_repeat" placeholder="确认密码">
      </div>
      <div class="text-group">
        <i class="input-icon left-module">
          <svg t="1688445411665" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2331" width="20" height="20"><path d="M896 192a64 64 0 0 1 64 64v512a64 64 0 0 1-64 64H128a64 64 0 0 1-64-64V256a64 64 0 0 1 64-64h768z m0 64H128v512h768V256z" fill="#b1b3b5" p-id="2332"></path><path d="M841.408 201.536l45.248 45.248-306.464 306.56a96 96 0 0 1-131.168 4.352l-4.608-4.32-306.56-306.592 45.28-45.248 306.56 306.592a32 32 0 0 0 42.24 2.624l3.008-2.656 306.464-306.56z" fill="#b1b3b5" p-id="2333"></path></svg>
        </i>
        <input v-model="email" type="text" name="email" placeholder="请输入电子邮箱地址">
        <button type="button" class="send-button right-module" :disabled="isEmailValid" @click="sendEmail()">
          {{codeTime > 0 ? '请稍后' + codeTime + '秒' : '获取验证码'}}
        </button>
      </div>
      <div class="text-group">
        <i class="input-icon left-module">
          <svg t="1688445995403" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2819" width="20" height="20"><path d="M141.248 702.08L96 747.328l181.008 181.024 45.264-45.248zM585.68 253.456l45.264-45.248L811.952 389.216 766.72 434.496z" fill="#b1b3b5" p-id="2820"></path><path d="M512 896m32 0l384 0q32 0 32 32l0 0q0 32-32 32l-384 0q-32 0-32-32l0 0q0-32 32-32Z" fill="#b1b3b5" p-id="2821"></path><path d="M761.232 109.248l153.552 153.824a64.16 64.16 0 0 1-0.032 90.56L317.744 950.64a32 32 0 0 1-22.64 9.36H96a32 32 0 0 1-32-32V728.896a32 32 0 0 1 9.376-22.624L670.752 109.248a63.952 63.952 0 0 1 90.48 0z m-45.296 45.28L128 742.112v153.888h153.824l587.68-587.696-153.568-153.76z" fill="#b1b3b5" p-id="2822"></path></svg>
        </i>
        <input v-model="code" type="text" name="code" placeholder="请输入邮箱验证码">
      </div>
    </form>
    <div class="text-group">
      <button class="warn" @click="register()">注册</button>
    </div>
    <div class="text-group">
      <span class="bottom-title">已有账号？<router-link to="/">我要登录</router-link></span>
    </div>
  </div>
</template>

<script>
  import {get, post} from "@/net";

  export default {
    name: 'RegisterPage',
    data() {
      return {
        username: '',
        password: '',
        password_repeat: '',
        email: '',
        code: '',
        isEmailValid: false,
        codeTime: 0
      }
    },
    methods: {
      sendEmail(){
        let re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$/
        if (!re.test(this.email) || this.email.length < 1) {
          this.$notify.warning('邮箱格式不正确')
          return;
        }
        this.isEmailValid = true
        post('/api/auth/register-valid-email/' + this.email, null, (message) => {
          this.codeTime = 60
          this.countdown()
          this.$notify.success(message)
        }, null, (message) => {
          this.$notify.warning(message)
          this.isEmailValid = false
        })
      },
      /* 注册 */
      register(){
        if (this.username.length < 2) {
          this.$notify.info('用户名长度大于 2')
          return;
        }
        if (this.password.length < 6) {
          this.$notify.info('密码长度需要大于 6')
          return;
        }
        if (this.password !== this.password_repeat) {
          this.$notify.info('两次输入密码不一致')
          return;
        }
        if (this.code.length !== 6) {
          this.$notify.info('验证码长度为 6')
          return;
        }
        post('/api/auth/register', this.$data, (message) => {
          this.$notify.success(message + '，请返回进行登录')
          this.$router.push('/')
        })
      },
      /* 倒计时 */
      countdown(){
        this.codeTime --
        if (this.codeTime < 1) {
          this.isEmailValid = false
        }else {
          setTimeout(this.countdown, 1000)
        }
      }
    }
  }
</script>

<style src="@/assets/css/auth.css" scoped>
</style>
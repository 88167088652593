<template>
  <div>
    <div v-for="(dialogue, index) in chatList" :key="index">
      <ChatLeft v-if="dialogue.role === 'assistant'" :dialogue="dialogue"/>
      <ChatRight v-if="dialogue.role === 'user'" :dialogue="dialogue" :index="index"/>
    </div>
  </div>
</template>

<script>
import ChatLeft from "@/components/chat/ChatLeft.vue";
import ChatRight from "@/components/chat/ChatRight.vue";

export default {
  name: 'ChatList',
  props: ['chatIndex'],
  components: {ChatRight, ChatLeft},
  data() {
    return {
    }
  },
  computed: {
    chatList() {
      if (typeof (this.$store.state.chats[this.chatIndex]) == 'undefined') {
        return []
      }
      return this.$store.state.chats[this.chatIndex].data || []
    }
  }
}
</script>
<style scoped>

</style>
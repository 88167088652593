import axios from "axios";
import Vue from 'vue'

const defaultError = () => Vue.prototype.$notify.error('发生了一点错误，请稍后在重试')
const defaultFailure = (message) => Vue.prototype.$notify.warning(message)

function post(url, data, success, header = null, failure = defaultFailure, error = defaultError){
    axios.post(url, data, { headers: header }).then(
        response => {
            const data = response.data;
            if (data.code === 200){
                success(data.message, data.data)
            }else if (data.code === 401){
                localStorage.removeItem('authorization')
                this.$store.state.auth.user = null
                failure('登录已过期，请重新登录', data.data)
            }else {
                failure(data.message, data.data)
            }
        }
    ).catch(error)
}

function get(url, success, failure = defaultFailure, error = defaultError){
    axios.get(url).then(
        response => {
            const data = response.data;
            if (data.code === 200){
                success(data.message, data.data)
            }else {
                failure(data.message, data.data)
            }
        }
    ).catch(error)
}

export { get, post }
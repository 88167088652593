<template>
  <div>
<!--    <Row>-->
<!--      <Col span="24" style="padding-bottom: 10px">-->
<!--        <Button type="primary" @click="modal = true">添加密钥</Button>-->
<!--        &nbsp;-->
<!--        <Button type="primary" @click="updateKeysTotal">更新密钥</Button>-->
<!--      </Col>-->
<!--    </Row>-->
    <Table border :loading="loading" :columns="THeader" :data="data"></Table>
    <Row>
      <Col span="24" style="padding-top: 10px">
        <Page :total="total" :page-size="size" show-total @on-change="changePage"/>
      </Col>
    </Row>
    <Modal v-model="deleteModal" width="360">
      <p slot="header" style="color:#f60;text-align:center">
        <Icon type="ios-information-circle"></Icon>
        <span> 删除确认</span>
      </p>
      <div style="text-align:center">
        <p>删除该条数据后将无法恢复。</p>
        <p>是否继续删除?</p>
      </div>
      <div slot="footer">
        <Button type="error" size="large" long :loading="modal_loading" @click="del">删除</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
import {get, post} from "@/net";

export default {
  name: 'UserList',
  data () {
    return {
      modal: false,
      deleteModal: false,
      modal_loading: false,
      deleteId: 0,
      loading: true,
      current: 1,
      size: 9,
      total: 0,
      pages: 0,
      THeader: [
        {
          title: 'Id',
          width: '100',
          key: 'id'
        },
        {
          title: '用户名',
          width: '200',
          key: 'username'
        },
        {
          title: '邮箱',
          width: '350',
          key: 'email'
        },
        {
          title: '权限',
          width: '200',
          key: 'role'
        },
        {
          title: '操作',
          key: 'action',
          width: 150,
          align: 'center',
          fixed: 'right',
          render: (h, params) => {
            return h('div', [
              h('Button', {
                props: {
                  type: 'primary',
                  size: 'small'
                },
                style: {
                  marginRight: '5px'
                },
                on: {
                  click: () => {
                    this.show(params.index)
                  }
                }
              }, '显示'),
              h('Button', {
                props: {
                  type: 'error',
                  size: 'small'
                },
                on: {
                  click: () => {
                    this.remove(params.index)
                  }
                }
              }, '删除')
            ]);
          }
        }
      ],
      data: []
    }
  },
  methods: {
    getAllUsers(){
      this.loading = true
      get('/api/admin/user' + '?page=' + this.current + '&limit=' + this.size, (_, data) => {
        this.total = data.total //总记录数
        this.pages = data.pages //当前页
        this.data = data.data
        this.loading = false
      })
    },
    show (index) {
      this.$Modal.info({
        title: '用户信息',
        content: `Id：${this.data[index].id}<br>邮箱：${this.data[index].email}<br>权限：${this.data[index].role}`
      })
    },
    changePage(index) {
      this.current = index
      this.getAllKeys()
    },
    remove (index) {
      this.deleteModal = true
      this.deleteId = index
    },
    del () {
      this.modal_loading = true;
      post('/api/admin/delete-key', { ids: this.data[this.deleteId].id }, (message) => {
        this.$notify.success(message)
        this.modal_loading = false;
        this.deleteModal = false;
        this.data.splice(this.deleteId, 1);
        this.getAllKeys()
      }, null, (message) => {
        this.$notify.warning(message)
        this.modal_loading = false;
      })
      // setTimeout(() => {
      //   this.$Message.success('Successfully delete');
      // }, 2000);
    }
  },
  mounted() {
    this.getAllUsers()
  }
}
</script>

<style>

</style>
<template>
  <div class="chat_right clearfix">
    <div class="chat_right_item_1">{{dialogue.role}}</div>
    <div class="chat_right_item_2">
      <div class="chat_right_time">{{dialogue.time}}</div>
      <div class="chat_right_content">
        {{dialogue.content}}
        <i class="error-ico" v-if="!dialogue.success" @click="reSend">
          <svg viewBox="0 0 1024 1024" color="rgb(245, 108, 108)" xmlns="http://www.w3.org/2000/svg"><path fill="currentColor" d="M512 85.333333C276.48 85.333333 85.333333 276.48 85.333333 512s191.146667 426.666667 426.666667 426.666667 426.666667-191.146667 426.666667-426.666667S747.52 85.333333 512 85.333333z m42.666667 640h-85.333334v-85.333333h85.333334v85.333333z m0-170.666666h-85.333334V298.666667h85.333334v256z"></path></svg>
        </i>
      </div>
    </div>
  </div>
</template>

<script>
  import '@/assets/css/chat.css'

  export default {
    name: 'ChatRight',
    data() {
      return {

      }
    },
    props: ['dialogue', 'index'],
    methods: {
      reSend(){
        this.$notify.info('正在重新发送')
        this.$bus.$emit('reSend', this.index)
      }
    }
  }
</script>

<style scoped>
  .error-ico{
    width: 24px;
    height: 24px;
    display: block;
    position: absolute;
    top: 14px;
    left: -30px;
  }
</style>
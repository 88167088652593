<template>
  <div class="chat_left clearfix">
    <div class="chat_left_item_1">KIL_AI</div>
    <div class="chat_left_item_2">
      <div class="chat_left_time">{{dialogue.time}}</div>
      <div class="chat_left_content" v-html="compiledMarkdown">
      </div>
    </div>
  </div>
</template>

<script>
import '@/assets/css/chat.css'
import MarkDownIt from 'markdown-it'
import hlJs from 'highlight.js'
import "github-markdown-css/github-markdown.css";
import "highlight.js/styles/atom-one-light.css"; //引入一种语法的高亮

export default {
  name: 'ChatLeft',
  data() {
    return {
      markdown: new MarkDownIt({
        html: true,
        linkify: true,
        typographer: true,
        highlight: function (str, lang) {
          // 得到经过highlight.js之后的html代码
          if (lang && hlJs.getLanguage(lang)) {
            return '<pre class="hljs" style="padding: 10px"><code>' +
                hlJs.highlight(str, {language: lang, ignoreIllegals: true }).value +
                '</code></pre>'
          }
          function escapeHtml(html) {
            let text = document.createTextNode(html);
            let div = document.createElement('div');
            div.appendChild(text);
            return div.innerHTML;
          }
          return '<pre class="hljs" style="padding: 10px"><code>' + escapeHtml(str) + '</code></pre>';
        },
      })
    }
  },
  props: ['dialogue'],
  computed: {
    compiledMarkdown() {
      return this.markdown.render(this.dialogue.content)
    }
  },
  methods: {
    /* 解析html代码 */

  }
}
</script>

<style>
  p code {
    padding: .2em .4em;
    margin: 0;
    /* font-size: 85%; */
    background-color: #afb8c133;
    border-radius: 6px;
  }
  code {
    white-space: pre-wrap;
  }
  ol{
    padding-left: 40px!important;
  }
</style>
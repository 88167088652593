<template>
  <div>
    <Row :gutter="16">
      <Col :xs="24" :sm="12" :lg="6" style="margin-bottom: 10px">
        <div class="count_card">
          <span class="count_card_title">用户数</span>
          <div class="count_card_container">
            <span>{{userCount}}</span>
          </div>
        </div>
      </Col>
      <Col :xs="24" :sm="12" :lg="6" style="margin-bottom: 10px">
        <div class="count_card">
          <span class="count_card_title">密钥数</span>
          <div class="count_card_container">
            <span>{{openAiKeyCount}}</span>
          </div>
        </div>
      </Col>
      <Col :xs="24" :sm="12" :lg="6" style="margin-bottom: 10px">
        <div class="count_card">
          <span class="count_card_title">待添加</span>
          <div class="count_card_container">
            <span>0</span>
          </div>
        </div>
      </Col>
      <Col :xs="24" :sm="12" :lg="6" style="margin-bottom: 10px">
        <div class="count_card">
          <span class="count_card_title">待添加</span>
          <div class="count_card_container">
            <span>0</span>
          </div>
        </div>
      </Col>
    </Row>
    <Row :gutter="16">
      <Col span="12">
        <span>待添加</span>
        <Progress :percent="25" :stroke-width="20" text-inside />
        <Progress :percent="45" :stroke-width="20" status="active" text-inside />
      </Col>
      <Col span="12">
        <span>待添加</span>
        <Progress :percent="65" :stroke-width="20" status="wrong" text-inside />
        <Progress :percent="100" :stroke-width="20" text-inside />
      </Col>
    </Row>
  </div>
</template>

<script>
import {get} from "@/net";

export default {
  name: 'AdminIndex',
  data () {
    return {
      userCount: 0,
      openAiKeyCount: 0
    }
  },
  methods: {
    getCount(){
      get('/api/admin/count', (_, data) => {
        this.userCount = data.userCount
        this.openAiKeyCount = data.openAiKeyCount
      })
    }
  },
  mounted() {
    this.getCount()
  }
}
</script>

<style>
.count_card{
  border: #3f8df0 1px solid;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 5px 5px rgba(0,0,0,.1);
}
.count_card_container{
  height: 80px;
}
</style>
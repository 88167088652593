<style>
.layout{
  border: 1px solid #d7dde4;
  background: #f5f7f9;
  position: relative;
  border-radius: 4px;
  overflow: hidden;
}
.layout-logo{
  width: 100px;
  height: 30px;
  background: #5b6270;
  border-radius: 3px;
  float: left;
  position: relative;
  top: 15px;
  left: 20px;
}
.layout-nav{
  width: fit-content;
  margin: 0 20px 0 auto;
}
.layout-header-bar{
  background: #fff!important;
  box-shadow: 0 1px 1px rgba(0,0,0,.1);
}
.left-menu-layout{
  position: static!important;
}
.left-menu-button{
  display: none;
}

@media screen and (max-width: 1023px) {
  .layout-logo{
    display: none;
  }
  .menu-item-list{
    display: none!important;
  }
  .left-menu-layout{
    position: absolute!important;
    height: 100%;
    left: -100%;
    z-index: 10;
  }
  .left-menu-button{
    display: block;
  }
}
</style>
<template>
  <div class="layout">
    <Layout>
      <Header :style="{padding: 0}" class="layout-header-bar">
        <Menu mode="horizontal" theme="light" active-name="1">
          <div class="layout-logo"></div>
          <div class="left-menu-button" :style="{float: 'left', position: 'relative'}">
            <Icon @click.native="expandNav" :class="rotateIcon" :style="{margin: '0 20px', display: 'inline-block'}" type="md-menu" size="24"></Icon>
          </div>
          <div class="layout-nav">
            <MenuItem name="1" class="menu-item-list">
              <Icon type="ios-navigate"></Icon>
              Item 1
            </MenuItem>
            <MenuItem name="2" class="menu-item-list">
              <Icon type="ios-keypad"></Icon>
              Item 2
            </MenuItem>

            <Dropdown trigger="click" placement="bottom-end" @on-click="changeMenu">
              <a href="javascript:void(0)">
                <Avatar style="margin: 0 20px 0 20px" src="https://i.loli.net/2017/08/21/599a521472424.jpg" />
              </a>
              <DropdownMenu slot="list">
                <DropdownItem name="editUserInfo">修改用户信息</DropdownItem>
                <DropdownItem name="logout" style="color: #ed4014" divided>退出登录</DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
        </Menu>
      </Header>
      <Layout :style="{/*padding: '0 24px 24px', */height: 'calc(100vh - 66px)'}">
        <Sider class="left-menu-layout" :style="leftMenuHide ? {left: '-100%'} : {left: 0}">
          <AdminLeftMenu @closeMenu="leftMenuHide = true"/>
        </Sider>
        <Layout :style="{padding: '0 24px 24px'/*, height: 'calc(100vh - 66px)'*/}">
          <Breadcrumb :style="{margin: '24px 0'}">
            <BreadcrumbItem to="/admin">Home</BreadcrumbItem>
            <template v-for="(item,index) in list">
              <BreadcrumbItem  :key="index" v-if="index !== 0 && item.name !== 'AdminIndex'">{{item.name}}</BreadcrumbItem>
            </template>
          </Breadcrumb>
          <Content :style="{padding: '24px', background: '#fff'}">
            <router-view/>
          </Content>
        </Layout>
      </Layout>
    </Layout>
  </div>
</template>
<script>
import AdminLeftMenu from "@/layout/admin/AdminLeftMenu.vue";
import {get} from "@/net";

export default {
  name: 'AdminLayout',
  data() {
    return {
      list: [],  // 容器
      isCollapsed: false,
      leftMenuHide: true
    }
  },
  components: {AdminLeftMenu},
  methods: {
    /* 退出登录 */
    logout() {
      if (confirm('确定退出账号吗')) {
        // this.$store.state.auth.user = null
        get('/api/auth/logout', () => {
          localStorage.removeItem('authorization')
          this.$router.push('/')
        })
      }
    },
    changeMenu(name) {
      if (name === 'editUserInfo') {
        console.log('修改用户信息')
      } else if (name === 'logout') {
        this.logout()
      }
    },
    expandNav() {
      this.leftMenuHide = !this.leftMenuHide
    }
  },
  computed: {
    rotateIcon () {
      return [
        'menu-icon',
        this.isCollapsed ? 'rotate-icon' : ''
      ];
    }
  },
  watch:{
    $route(){   // 监听路由变化
      this.list = []
      this.list = this.$route.matched
    }
  },
  mounted(){  // 刷新时
    this.list = this.$route.matched
  }
}
</script>

<template>
  <router-view/>
</template>

<script>
  import {get} from "@/net";

  export default {
    name: 'App',
    // mounted() {
    //   if (this.$store.state.auth.user == null) {
    //     get('/api/user/me', (_,data) => {
    //       this.$store.state.auth.user = data
    //       this.$notify.success('已登录');
    //       if (data.role === 'admin') {
    //         this.$router.push('/admin')
    //       } else {
    //         this.$router.push('/chat')
    //       }
    //     }, () => {
    //       this.$store.state.auth.user = null
    //       localStorage.removeItem('authorization')
    //     })
    //   }
    // }
  }
</script>

<style>
  html,body{
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
  }
</style>

<template>
  <!-- @after-enter 是 Vue 的过渡事件钩子，用于监听元素进入过渡动画结束后的事件 -->
  <!-- @after-leave 是 Vue 的过渡事件钩子，用于监听元素离开过渡动画结束后的事件。 -->
  <transition name="fade" @after-leave="afterLeave" @after-enter="afterEnter">
    <!-- @mouseenter 是 Vue 中的事件修饰符，用于监听鼠标进入（hover）事件。 -->
    <!-- @mouseleave 是 Vue 中的事件修饰符，用于监听鼠标离开（mouseout）事件 -->
    <div class="notification" :style="style" v-show="visible" @mouseenter="clearTimer" @mouseleave="createTimer">
      <div class="notice-head">
        <i class="notice-ico" v-html="noticeIco"></i>
        <h2 class="notice-title" v-text="noticeTitle"></h2>
        <i v-show="btn" class="notice-btn" @click="handleClose">
          <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg"><path fill="currentColor" d="M764.288 214.592 512 466.88 259.712 214.592a31.936 31.936 0 0 0-45.12 45.12L466.752 512 214.528 764.224a31.936 31.936 0 1 0 45.12 45.184L512 557.184l252.288 252.288a31.936 31.936 0 0 0 45.12-45.12L557.12 512.064l252.288-252.352a31.936 31.936 0 1 0-45.12-45.184z"></path></svg>
        </i>
      </div>
      <div class="notice-content" v-if="content !== null">{{ content }}</div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'NotificationCard',
  data () {
    return {
      verticalOffset: 0,
      autoClose: 3000,
      height: 0,
      visible: false,
    };
  },
  props: {
    type: {
      type: String,
      default: 'info'
    },
    title: {
      type: String,
      default: null
    },
    content: {
      type: String,
      default: null
    },
    btn: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    style() {
      return {
        position: "fixed",
        right: "20px",
        top: `${this.verticalOffset}px`,
      };
    },
    noticeTitle() {
      if (this.type === 'warning' && this.title === null) {
        return '警告'
      }else if (this.type === 'success' && this.title === null) {
        return '成功'
      }else if (this.type === 'error' && this.title === null) {
        return '错误'
      }else if (this.title === null) {
        return '提示'
      } else {
        return this.title
      }
    },
    noticeIco() {
      if (this.type === 'warning') {
        return  '<svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" color="rgb(230, 162, 60)"><path fill="currentColor" d="M512 64a448 448 0 1 1 0 896 448 448 0 0 1 0-896zm0 192a58.432 58.432 0 0 0-58.24 63.744l23.36 256.384a35.072 35.072 0 0 0 69.76 0l23.296-256.384A58.432 58.432 0 0 0 512 256zm0 512a51.2 51.2 0 1 0 0-102.4 51.2 51.2 0 0 0 0 102.4z"></path></svg>'
      } else if (this.type === 'success') {
        return  '<svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" color="rgb(103, 194, 58)"><path fill="currentColor" d="M512 64a448 448 0 1 1 0 896 448 448 0 0 1 0-896zm-55.808 536.384-99.52-99.584a38.4 38.4 0 1 0-54.336 54.336l126.72 126.72a38.272 38.272 0 0 0 54.336 0l262.4-262.464a38.4 38.4 0 1 0-54.272-54.336L456.192 600.384z"></path></svg>'
      } else if (this.type === 'error') {
        return  '<svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" color="rgb(245, 108, 108)"><path fill="currentColor" d="M512 64a448 448 0 1 1 0 896 448 448 0 0 1 0-896zm0 393.664L407.936 353.6a38.4 38.4 0 1 0-54.336 54.336L457.664 512 353.6 616.064a38.4 38.4 0 1 0 54.336 54.336L512 566.336 616.064 670.4a38.4 38.4 0 1 0 54.336-54.336L566.336 512 670.4 407.936a38.4 38.4 0 1 0-54.336-54.336L512 457.664z"></path></svg>'
      } else {
        return  '<svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" color="rgb(144, 147, 153)"><path fill="currentColor" d="M512 64a448 448 0 1 1 0 896.064A448 448 0 0 1 512 64zm67.2 275.072c33.28 0 60.288-23.104 60.288-57.344s-27.072-57.344-60.288-57.344c-33.28 0-60.16 23.104-60.16 57.344s26.88 57.344 60.16 57.344zM590.912 699.2c0-6.848 2.368-24.64 1.024-34.752l-52.608 60.544c-10.88 11.456-24.512 19.392-30.912 17.28a12.992 12.992 0 0 1-8.256-14.72l87.68-276.992c7.168-35.136-12.544-67.2-54.336-71.296-44.096 0-108.992 44.736-148.48 101.504 0 6.784-1.28 23.68.064 33.792l52.544-60.608c10.88-11.328 23.552-19.328 29.952-17.152a12.8 12.8 0 0 1 7.808 16.128L388.48 728.576c-10.048 32.256 8.96 63.872 55.04 71.04 67.84 0 107.904-43.648 147.456-100.416z"></path></svg>'
      }
    }
  },
  mounted() {
    this.createTimer();
  },
  methods: {
    handleClose (e) {
      e.preventDefault()
      this.$emit('close')
    },
    // 过度动画后事件
    afterLeave () {
      this.$emit('closed')
    },
    createTimer() {
      if (this.autoClose) {
        this.timer = setTimeout(() => {
          this.visible = false;
        }, this.autoClose);
      }
    },
    clearTimer() {
      if (this.timer) {
        // 清除通过 setTimeout() 创建的定时器
        clearTimeout(this.timer);
      }
    },
    afterEnter() {
      // debugger // eslint-disable-line
      this.height = this.$el.offsetHeight;
    }
  },
  beforeDestroy() {
    this.clearTimer();
  },
}
</script>

<style scoped>
.notification{
  width: 330px;
  background-color: #ffffff;
  color: #606266;
  padding: 14px 20px;
  box-shadow: 0 0 12px rgba(0, 0, 0, .12);
  box-sizing: border-box;
  border: 1px solid #ebeef5;
  border-radius: 8px;
  transition: all .3s;
  position: relative;
  z-index: 999999;
}
.fade-enter-active{
  animation: slideAppear 0.3s;
}
.fade-leave-active{
  transition: opacity .1s linear;
  opacity: 0
}

/* 动画 */
@keyframes slideAppear {
  from{
    transform: translateX(100%);
  }
  to{
    transform: translateX(0px);
  }
}

/* 通知类容 */
.notice-head{
  height: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.notice-ico{
  width: 24px;
  height: 24px;
}
.notice-title{
  flex: 1;
  font-size: 16px;
  font-weight: 700;
  color: #303133;
  margin: 0 0 0 13px;
}
.notice-btn{
  width: 16px;
  height: 16px;
}
.notice-content{
  padding: 0;
  font-size: 14px;
  margin-left: 37px;
  margin-top: 6px;
}
</style>
<template>
  <div class="auth-index">
    <div class="welcome">
      <img class="welcome-img" src="../../assets/welcome-background.jpg" alt="">
    </div>
    <div class="auth">
      <router-view/>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'AuthIndex'
  }
</script>

<style scoped>
.auth-index{
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.welcome-img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
}

.welcome{
  flex: 1;
}

.auth{
  width: 410px;
  box-sizing: border-box;
}
</style>
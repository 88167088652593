<template>
  <div>
    <Row>
      <Col span="24" style="padding-bottom: 10px">
        <Button type="primary" @click="modal = true">添加密钥</Button>
        &nbsp;
        <Button type="primary" @click="updateKeysTotal">更新密钥</Button>
      </Col>
    </Row>
    <Table border :loading="loading" :columns="THeader" :data="data"></Table>
    <Row>
      <Col span="24" style="padding-top: 10px">
        <Page :total="total" :page-size="size" show-total @on-change="changePage"/>
      </Col>
    </Row>
    <Modal
        v-model="modal"
        title="添加密钥"
        @on-ok="addKey"
        @on-cancel="cancel">
      <Form ref="formValidate" :model="formValidate" :label-width="80">
        <FormItem label="密钥">
          <Input v-model="formValidate.key" placeholder="请输入你要添加的密钥"></Input>
        </FormItem>
<!--        <FormItem label="模型">-->
<!--          <CheckboxGroup v-model="formValidate.models">-->
<!--            <Checkbox label="gpt-4"></Checkbox>-->
<!--            <Checkbox label="gpt-3.5"></Checkbox>-->
<!--          </CheckboxGroup>-->
<!--        </FormItem>-->
      </Form>
    </Modal>
    <Modal v-model="deleteModal" width="360">
      <p slot="header" style="color:#f60;text-align:center">
        <Icon type="ios-information-circle"></Icon>
        <span> 删除确认</span>
      </p>
      <div style="text-align:center">
        <p>删除该条数据后将无法恢复。</p>
        <p>是否继续删除?</p>
      </div>
      <div slot="footer">
        <Button type="error" size="large" long :loading="modal_loading" @click="del">删除</Button>
      </div>
    </Modal>
  </div>
</template>
<script>
import {get, post} from "@/net";

export default {
  name: 'keyList',
  data () {
    return {
      modal: false,
      deleteModal: false,
      modal_loading: false,
      deleteId: 0,
      loading: true,
      current: 1,
      size: 9,
      total: 0,
      pages: 0,
      THeader: [
        {
          title: 'Id',
          width: '100',
          key: 'id'
        },
        {
          title: '密钥',
          width: '500',
          key: 'key'
        },
        {
          title: '模型',
          width: '200',
          key: 'models'
        },
        {
          title: '状态',
          width: '100',
          key: 'state'
        },
        {
          title: '总额',
          width: '100',
          key: 'total'
        },
        {
          title: '已使用',
          width: '100',
          key: 'totalUsage'
        },
        {
          title: '过期时间',
          width: '300',
          key: 'expirationTime'
        },
        {
          title: '创建时间',
          width: '300',
          key: 'createTime'
        },
        {
          title: '更新时间',
          width: '300',
          key: 'updateTime'
        },
        {
          title: '操作',
          key: 'action',
          width: 150,
          align: 'center',
          fixed: 'right',
          render: (h, params) => {
            return h('div', [
              h('Button', {
                props: {
                  type: 'primary',
                  size: 'small'
                },
                style: {
                  marginRight: '5px'
                },
                on: {
                  click: () => {
                    this.show(params.index)
                  }
                }
              }, '显示'),
              h('Button', {
                props: {
                  type: 'error',
                  size: 'small'
                },
                on: {
                  click: () => {
                    this.remove(params.index)
                  }
                }
              }, '删除')
            ]);
          }
        }
      ],
      data: [],
      formValidate: {
        key: '',
        // models: ''
      }
    }
  },
  methods: {
    getAllKeys(){
      this.loading = true
      get('/api/admin/keys' + '?page=' + this.current + '&limit=' + this.size, (_, data) => {
        this.total = data.total //总记录数
        this.pages = data.pages //当前页
        this.data = data.data
        this.loading = false
      })
    },
    updateKeysTotal(){
      get('/api/admin/update-keys-total', () => {
        this.getAllKeys()
      })
    },
    changePage(index) {
      this.current = index
      this.getAllKeys()
    },
    addKey () {
      post('/api/admin/add-key', this.$data.formValidate, (message) => {
        this.$notify.success(message)
        this.getAllKeys()
      })
      this.formValidate.key = ''
    },
    cancel () {
      // this.$Message.info('Clicked cancel');
    },
    show (index) {
      this.$Modal.info({
        title: '密钥信息',
        content: `Id：${this.data[index].id}<br>密钥：<input type="text" value="${this.data[index].key}" readonly><br>状态：${this.data[index].state}<br>总额：${this.data[index].total}<br>已使用：${this.data[index].totalUsage}<br>支持模型：${this.data[index].models}<br>添加时间：${this.data[index].createTime}<br>修改时间：${this.data[index].updateTime}`
      })
    },
    remove (index) {
      this.deleteModal = true
      this.deleteId = index
    },
    del () {
      this.modal_loading = true;
      post('/api/admin/delete-key', { ids: this.data[this.deleteId].id }, (message) => {
        this.$notify.success(message)
        this.modal_loading = false;
        this.deleteModal = false;
        this.data.splice(this.deleteId, 1);
        this.getAllKeys()
      }, null, (message) => {
        this.$notify.warning(message)
        this.modal_loading = false;
      })
      // setTimeout(() => {
      //   this.$Message.success('Successfully delete');
      // }, 2000);
    }
  },
  mounted() {
    this.getAllKeys()
  }
}
</script>

<template>
  <div class="login">
    <div class="subject">
      <h1 class="title">登 录</h1>
      <p class="details">欢迎使用KIL_AI，在此之前请完成登录。</p>
    </div>
    <form>
      <div class="text-group">
        <i class="input-icon left-module">
          <svg t="1688445363370" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2073" width="20" height="20"><path d="M512 64c106.032 0 192 85.968 192 192s-85.968 192-192 192-192-85.968-192-192S405.968 64 512 64z m0 64a128 128 0 1 0 0 256 128 128 0 0 0 0-256zM512 480c208.176 0 378.688 152.096 383.872 343.024L896 832h-64c0-158.336-142.624-288-320-288-174.32 0-315.072 125.232-319.872 279.84L192 832H128c0-195.12 172.56-352 384-352zM192 832a32 32 0 0 0 28.272 31.792L224 864h576a32 32 0 0 0 31.792-28.272L832 832h64a96 96 0 0 1-90.352 95.84L800 928H224a96 96 0 0 1-95.84-90.352L128 832h64z" fill="#b1b3b5" p-id="2074"></path></svg>
        </i>
        <input v-model="username" type="text" name="username" placeholder="用户名/邮箱">
      </div>
      <div class="text-group">
        <i class="input-icon left-module">
          <svg t="1688444703568" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1598" id="mx_n_1688444703569" width="20" height="20"><path d="M832 320a64 64 0 0 1 64 64v448a64 64 0 0 1-64 64H192a64 64 0 0 1-64-64V384a64 64 0 0 1 64-64h640z m0 64H192v448h640V384z" fill="#b1b3b5" p-id="1599"></path><path d="M480 512m32 0l0 0q32 0 32 32l0 128q0 32-32 32l0 0q-32 0-32-32l0-128q0-32 32-32Z" fill="#b1b3b5" p-id="1600"></path><path d="M512 64c106.032 0 192 85.968 192 192v128H320v-128c0-106.032 85.968-192 192-192z m0 64a128 128 0 0 0-127.84 121.6L384 256v64h256v-64a128 128 0 0 0-121.6-127.84L512 128z" fill="#b1b3b5" p-id="1601"></path></svg>
        </i>
        <input v-model="password" type="password" name="password" placeholder="密码">
      </div>
    </form>
    <div class="text-group">
      <button class="success" @click="login()">登录</button>
    </div>
    <div class="text-group">
      <button class="warn" @click="$router.push('/register')">注册</button>
    </div>
  </div>
</template>

<script>
import {post} from "@/net";

  export default {
    name: 'LoginPage',
    data() {
      return{
        username: '',
        password: '',
      }
    },
    methods: {
      login() {
        if (this.username.length > 0 && this.password.length >= 6) {
          post('/api/auth/login', this.$data,(message, data) =>{
            localStorage.setItem('authorization', data.token)
            this.$notify.success('登录成功')
            // this.$router.push('/chat')
            this.$store.state.auth.user = data
            if (data.role === 'admin') {
              this.$router.push('/admin')
            } else {
              this.$router.push('/chat')
            }
            // get('/api/user/me', (_,data) => {
            //   this.$store.state.auth.user = data
            //   if (data.role === 'admin') {
            //     this.$router.push('/admin')
            //   } else {
            //     this.$router.push('/chat')
            //   }
            // })
          },{
            'Content-Type': 'application/x-www-form-urlencoded'
          })
        }else {
          this.$notify.warning('用户名密码不能为空，并且密码长度大于6')
        }
      }
    }
  }
</script>

<style src="@/assets/css/auth.css" scoped>
</style>
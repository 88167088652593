/* 创建Vuex中最核心的store */

import Vuex from 'vuex'
import Vue from "vue";
import {getNowDate} from "@/utils";
/* 应用Vuex插件 */
Vue.use(Vuex)
/* 准备actions 用于相应组件中的动作 */
const actions = {
    addChat(context, value) {
        if (value != null) {
            const chat = {"role":"user", "content": value, "time": getNowDate()}
            context.commit('ADD_CHAT', chat)
        }
    }
}

/* 准备mutations 用于操作数据（state） */
const mutations = {
    SAVE_CHATS(state, value) {
        state.chats = value
    },
    ADD_CHAT(state, value) {
        state.chats[0].data.push(value)
        // localStorage.setItem('chats', JSON.stringify(state))
    }
}

/* 准备state 用于存储数据 */
const state = {
    chats: JSON.parse(localStorage.getItem('chats')) !== null && JSON.parse(localStorage.getItem('chats')).length > 1?
        JSON.parse(localStorage.getItem('chats')) : [{"name":"KIL_AL","data":[]}],
    auth: {
        user: null
    }
}

/* 创建并暴露store */
export default new Vuex.Store({
    /* 简写形式 */
    actions,
    mutations,
    state
});
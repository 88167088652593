/**
 * 创建通知插件的调用方法
 */
import Vue from 'vue'
import Component from '@/components/notification/func-notification'


/**
 * 思考：我们为什么要这么去做呢？因为我们这里是一个方法，我们要通过JS的方法调用去创建一个Vue的组件，这个组件我们怎么去创建最方便呢？
 * 我们肯定是通过去new的方式，我们可以直接通过new Vue去创建一个组件，那么我们同样可以通过Vue.extend返回的方法，我们去创建一个组件。
 */

// 创建一个 Vue 子类
const NotificationConstructor = Vue.extend(Component)

// 存储通知组件实例列表
const instances = []
// 用来生成组件的id
let seed = 1

const removeInstance = instance => {
    if (!instance) return
    // 获取通知组件实例列表的长度
    const len = instances.length
    // 查找符合条件的元素在数组中的索引
    const index = instances.findIndex(inst => instance.id === inst.id)
    // splice(index, 1) 是 JavaScript 数组的一个方法，用于从数组中移除指定索引位置的元素，并返回被移除的元素
    instances.splice(index, 1)

    if (len < 1) return
    // 当前组件实例的高度
    const removeHeight = instance.vm.height
    //
    for (let i = index; i < len - 1; i++) {
        // 重新计算bottom的高度
        instances[i].verticalOffset = parseInt(instances[i].verticalOffset) - removeHeight - 16
    }
}

const notify = {
    success(options) {
        if (Vue.prototype.$isServer) return
        options = parseContentObject(options)
        options['type'] = 'success'
        addInstance(options)
    },
    warning(options) {
        if (Vue.prototype.$isServer) return
        options = parseContentObject(options)
        options['type'] = 'warning'
        addInstance(options)
    },
    error(options) {
        if (Vue.prototype.$isServer) return
        options = parseContentObject(options)
        options['type'] = 'error'
        addInstance(options)
    },
    info(options) {
        if (Vue.prototype.$isServer) return
        options = parseContentObject(options)
        options['type'] = 'info'
        addInstance(options)
    }
}

const parseContentObject = options => {
    if (typeof options === 'string') {
        return {content: options}
    } else if (typeof options === 'object') {
        return options;
    }else {
        return {}
    }
}

const addInstance = (options) => {
    const { autoClose, ...rest } = options
    const instance = new NotificationConstructor({
        propsData: { ...rest },
        data: {
            autoClose: autoClose === undefined ? 3000 : autoClose
        }
    })
    instance.id = `notification_${seed++}` // 生成唯一id，用来做删除时使用
    // 通过$mount()不传节点的时候，只是生成了一个$el的对象，但是这个时候还没有真正的插入到DOM节点里面去，此时标签节点已经生成好了
    instance.vm = instance.$mount()
    // 将新元素添加到 body 元素末尾
    // instance.vm.$el 是Vue实例的一个属性，用于访问Vue实例对应的DOM元素。
    document.body.appendChild(instance.vm.$el)
    instance.visible = true

    // 计算高度
    let verticalOffset = 0
    instances.forEach(item => {
        // $el.offsetHeight 是 Vue 实例的一个属性，用于获取挂载的元素的高度。
        verticalOffset += item.$el.offsetHeight + 16 // 每个组件高度间隔16px
    })
    verticalOffset += 16 // 最下面的组件底部距离最底部也有16px的间隙
    // 传给func-notification.js用来做bottom的高度
    instance.verticalOffset = verticalOffset

    // 添加实例
    instances.push(instance)

    // 过度动画后事件
    // 绑定自定义事件
    instance.vm.$on('closed', () => {
        removeInstance(instance)
        // document.body.removeChild 是 JavaScript 的一个原生方法，用于从 HTML 文档的 body 元素中移除子元素。
        document.body.removeChild(instance.vm.$el)
        // instance.vm.$destroy() 是 Vue 实例的一个方法，用于销毁该实例并解除与 DOM 的绑定。
        instance.vm.$destroy()
    })

    // 绑定自定义事件
    instance.vm.$on('close', () => {
        instance.vm.visible = false
    })

    //
    return instance.vm
}

export default notify